<template>
  <!--修改密码 -->

  <app-dialog2 ref="dialog" title="修改密码" :width="500">
    <app-status ref="page" class="appDetail">
      <el-form ref="detailForm" :rules="formRules" :model="formData" label-position="top" class="appDetail-form">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="用户名称">
              <el-input disabled :model-value="userInfo?.user?.username" class="appDetail-form-input"></el-input>
            </el-form-item>
            <el-form-item prop="oldPassword" label="旧密码">
              <el-input show-password placeholder="请输入旧密码" v-model="formData.oldPassword" class="appDetail-form-input"> </el-input>
            </el-form-item>
            <el-form-item prop="newPassword" label="新密码">
              <el-input show-password placeholder="请输入新密码" v-model="formData.newPassword" class="appDetail-form-input"> </el-input>
            </el-form-item>
            <el-form-item prop="confirmPassword" label="重复新密码">
              <el-input show-password placeholder="请输入重复新密码" v-model="formData.confirmPassword" class="appDetail-form-input"></el-input>
            </el-form-item>
            <!--            <el-alert-->
            <!--              :closable="false"-->
            <!--              type="warning"-->
            <!--              title="新密码需符合以下规则：大小写字母+数字+特殊符号，特殊符号包括!@#$%^&*?."-->
            <!--            />-->
          </el-col>
        </el-row>
      </el-form>

      <div class="appDetail-footer">
        <div class="appDetail-buttongourp">
          <el-button @click="$refs.dialog.close()" type="primary" plain class="appDetail-buttongourp-button"> 取消 </el-button>
          <el-button @click="formSubmit()" type="primary" class="appDetail-buttongourp-button"> 确定 </el-button>
        </div>
      </div>
    </app-status>
  </app-dialog2>
</template>

<script>
import pattern from '@/utils/pattern';
import { mapState } from 'vuex';

export default {
  emits: ['confirm'],
  computed: {
    ...mapState('user', ['userInfo']),
  },
  components: {},
  data() {
    return {
      formData: {
        confirmPassword: '',
        newPassword: '',
        oldPassword: '',
      },
      formRules: {
        oldPassword: [
          {
            required: true,
            message: '请输入旧密码',
            trigger: 'blur',
          },
        ],
        newPassword: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'blur',
          },
          {
            message: '新密码格式不正确',
            pattern: pattern.password,
            trigger: 'blur',
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: '请输入重复新密码',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (this.formData.newPassword != value) {
                callback(new Error('确认密码与密码不同，请确认'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 打开弹框
     */
    async open() {
      // 打开弹框
      await this.$refs.dialog.open();
    },

    /**
     * 表单提交
     */
    formSubmit() {
      // 表单验证
      this.$refs.detailForm.validate(async (valid) => {
        // 验证通过
        if (valid) {
          // 加载状态
          const loading = this.$loading();

          try {
            // 发送请求
            await this.$request.put('/auth/edit-pwd', {
              old: this.formData.oldPassword,
              new: this.formData.newPassword,
              new_confirmation: this.formData.confirmPassword,
            });

            // 弹出提示
            this.$message.success('修改密码成功');

            // 弹出成功
            this.$emit('confirm');

            // 关闭弹窗
            this.$refs.dialog.close();
          } catch (e) {
            // 提示错误
            this.$message.error(e.message);
          } finally {
            // 关闭加载
            loading.close();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
