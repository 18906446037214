/**
 * 正则规则工具
 */
const pattern = {
  // 密码
  // password: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}/,
  // password:/^[A-Za-z0-9]+$/,
  // password: /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{6,20}$/,

  // 身份证
  idcard: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,

  //包含0 整数
  integer: /^(0|[1-9][0-9]*)$/,

  //匹配由26个英文字母区分大小写
  english: '^[A-Za-z]+$',
};
export default pattern;
