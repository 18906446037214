import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import NProgress from 'nprogress';

import index from '@/views/index.vue';
import general from './general';
import distributor from './distributor';
import supplier from './supplier';
import agent from './agent';
import store from '@/store';
import ElMessageBox from 'element-plus';

// 进度条
NProgress.configure({
  // easing: 'ease', // 动画方式
  // speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载
  // trickleSpeed: 200, // 自动递增间隔
  // minimum: 0.3 // 初始化时的最小百分比
});

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: {
      auth: {
        required: true,
        list: [],
      },
    },
    children: [
      {
        path: '/general',
        name: 'general',
        component: () => import('@/views/app/index.vue'),
        redirect: { name: general.default.name },
        children: general.route,
        meta: {
          auth: {
            required: true,
            list: [],
          },
        },
      },
      {
        path: '/supplier',
        name: 'supplier',
        component: () => import('@/views/app/index.vue'),
        redirect: { name: supplier.default.name },
        children: supplier.route,
        meta: {
          auth: {
            required: true,
            list: [],
          },
        },
      },
      {
        path: '/distributor',
        name: 'distributor',
        component: () => import('@/views/app/index.vue'),
        redirect: { name: distributor.default.name },
        children: distributor.route,
        meta: {
          auth: {
            required: true,
            list: [],
          },
        },
      },
      {
        path: '/agent',
        name: 'agent',
        component: () => import('@/views/app/index.vue'),
        redirect: { name: agent.default.name },
        children: agent.route,
        meta: {
          auth: {
            required: true,
            list: [],
          },
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/app/login.vue'),
    meta: {
      auth: {
        required: false,
        list: [],
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/app/register.vue'),
    meta: {
      auth: {
        required: false,
        list: [],
      },
    },
  },
  {
    path: '/s/:id',
    name: 'short',
    component: () => import('@/views/app/short.vue'),
    meta: {
      auth: {
        required: false,
        list: [],
      },
    },
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import('@/views/app/verification.vue'),
    meta: {
      auth: {
        required: false,
        list: [],
      },
    },
  },
  {
    path: '/guide_scan',
    name: 'guide_scan',
    component: () => import('@/views/app/guide_scan.vue'),
    meta: {
      auth: {
        required: false,
        list: [],
      },
    },
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/',
  },
];

// 创建路由
let router;
if (process.env.NODE_ENV == 'TEST') {
  router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
  });
} else {
  router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });
}

// 检查路由
function routerCheck(to, next) {
  // 获取页面分类
  const [, category] = to.matched;

  // 获取权限分类
  const authType = store.state.user.userInfo.user.admin_type;

  // 权限跳转
  function routerCheckTo(name) {
    // 则继续跳转
    if (category?.name == name) {
      next();
    } else {
      // 清除缓存
      store.commit('app/removeCache');

      // 跳转至拥有权限的页面首页
      next({
        name,
      });
    }
  }

  switch (authType) {
    case 0:
      routerCheckTo('general');
      break;
    case 1:
      routerCheckTo('supplier');
      break;
    case 2:
      routerCheckTo('agent');
      break;
    case 3:
      routerCheckTo('distributor');
      break;
    default:
      next({
        name: 'login',
      });
      break;
  }
}

// 加载钩子判断权限
router.beforeEach(async (to, from, next) => {
  // 不使用缓存 或者 缓存中没有当前页面，则显示进度条
  if (!to.params.userCache || !store.state.app.cache.some((item) => item.name == to.name)) {
    // 显示进度条
    NProgress.start();
  }

  // 获取页面级别
  const [, category] = to.matched;

  // 添加缓存
  if (category && ['general', 'supplier', 'distributor'].includes(category.name)) {
    // 获取默认页面
    const cache = {
      general,
      supplier,
      distributor,
    }[category.name];

    // 添加默认页面
    store.commit('app/pushCache', {
      title: cache.default.meta.title,
      name: cache.default.name,
      default: true,
      resetCache: false,
    });

    if (cache.default.name != to.name) {
      // 添加当前页面
      store.commit('app/pushCache', {
        title: to.meta.title,
        name: to.name,
        resetCache: !to.params.userCache,
      });
    }
  }

  // 检查路由是否要求登录
  if (to?.meta?.auth?.required) {
    // 如果路由要求登录，那么检查是否已经登录
    if (store.getters['user/isLogin']) {
      // 获取权限
      const auth = store.state.user.userInfo.auth;

      // 已登录则判断权限列表
      if (auth?.length) {
        // 有权限列表继续跳转
        routerCheck(to, next);
      } else {
        // 没有权限列表跳转登录页
        next({
          name: 'login',
        });

        // 提示
        ElMessageBox.alert('您的权限组中没有权限，请联系管理员添加', '警告');
      }
      // if (process.env.NODE_ENV == "production" || process.env.NODE_ENV == "TEST") {
      //   routerCheck(to, next)
      //   // routerCheck(store.state['user/userInfo'].user.admin_type, to, next)
      // } else {
      //   next()
      // }
    } else {
      // 记录来源页面
      store.commit('user/loginBackUrl', to.name);

      // 未登录则跳转登录页
      next({
        name: 'login',
      });
    }
  } else {
    // 不要求登录则直接继续
    next();
  }
});

router.afterEach(() => {
  // windowSystem.scrollTo(0, 0);
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done();
});

export default router;
