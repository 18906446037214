<template>
  <div class="appMenu">
    <!-- 顶部 -->
    <div class="appMenu-header">
      <!-- logo -->
      <div
        class="appMenu-logo"
        :style="{
          'background-image': 'url(' + logo + ')',
        }"></div>
    </div>
    <!-- 搜索 -->
    <div class="appMenu-search">
      <el-autocomplete
        class="appMenu-search-input"
        v-model="searchKey"
        :fetch-suggestions="querySearch"
        value-key="label"
        placeholder="检索内容"
        @select="searchSelect"
        input-style="appMenu-search-input-content"></el-autocomplete>
    </div>

    <div class="appMenu-list">
      <el-scrollbar>
        <app-menu-primary :menu="item" v-for="(item, index) in menuList" :key="index" @toNav="nav"></app-menu-primary>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import appMenuPrimary from './app-menu-primary.vue';
export default {
  name: 'appMenu',
  components: {
    appMenuPrimary,
  },
  props: {
    menuname: {
      type: String,
      value: '',
    },
  },
  watch: {
    menuname: {
      immediate: true,
      handler(value) {
        if (value.children) {
          this.menuList = value.children;
          // if (this.menuList[0].children){
          //   this.nav(this.menuList[0].children[0])
          // }else{
          //   this.nav(this.menuList[0])
          // }
        } else {
          this.menuList = value;
          // this.nav(this.menuList)
        }
      },
    },
  },
  computed: {
    /**
     * 搜索列表
     */
    searchList() {
      return this.menuList.reduce((result, item) => {
        return this.routeList(item, result);
      }, []);
    },
  },
  data() {
    return {
      logo: process.env.VUE_APP_API_URL + `storage/images/admin/logo.png`,
      searchKey: '',
      menuList: [],
    };
  },
  mounted() {},
  methods: {
    ...mapMutations('app', ['pushCache']),

    /**
     * 查找当前路由是否存在于menu中
     */
    routeList(menu, result = []) {
      if (menu.children) {
        menu.children.forEach((item) => {
          this.routeList(item, result);
        });
      } else {
        result.push({
          value: menu.path,
          label: menu.name,
        });
      }

      return result;
    },

    /**
     * 导航
     * @param {Object} detail 路由详情
     */
    nav(detail) {
      if (detail.menutype == 'left') {
        // 跳转页面
        this.$router.push({
          name: detail.path,
        });
      } else if (detail.menutype == 'blank') {
        window.open(detail.url);
      }
    },

    /**
     * 路由搜索
     */
    querySearch(queryString, callback) {
      const results = queryString
        ? this.searchList.filter((item) => {
            if (item.label.includes(queryString)) {
              return item;
            }
          })
        : [];
      callback(results);
    },

    /**
     * 搜索跳转
     */
    searchSelect(e) {
      this.$router.push({
        name: e.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appMenu {
  //background-color: #1a1d29;
  //color: #fff;
  background: #ffffff;
  color: #333333;
  display: flex;
  flex-direction: column;
  width: 240px;

  // 顶部
  &-header {
    background-color: #4388ef;
    padding: 0 20px;
    height: 66px;
  }

  // 列表
  &-list {
    height: 0;
    flex: 1;
  }

  // logo
  &-logo {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    // justify-content: center;
    height: 60px;
    margin-top: 6px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  // 搜索
  &-search {
    //background-color: #212533;
    padding: 10px;
    margin: 0 auto;
    &-input {
      width: 100%;

      .el-input__inner {
        background-color: transparent;
        box-shadow: 0 0 0 1px #606266 inset;
      }
    }
  }

  // 元素
  &-item {
  }
}
</style>
