<template>
  <div class="appHeaderExtend" v-if="show">
    <teleport to="#appContent-header">
      <slot></slot>
    </teleport>
  </div>
</template>

<script>
export default {
  name: 'app-header-extend',
  computed: {},
  watch: {
    $route: {
      immediate: true,
      async handler() {
        this.show = false;
        await this.$nextTick();
        this.show = true;
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
