<template>
  <div class="appStatus" v-loading="statusCode == 'init'">
    <slot></slot>

    <!-- 状态空数据 -->
    <template v-if="statusCode == 'empty'">
      <div class="appStatus-empty">
        <el-empty :description="text.custom || text.list.empty" />
      </div>
    </template>

    <!-- 状态错误 -->
    <template v-else-if="statusCode == 'error'">
      <template v-if="$slots.error">
        <slot name="error"></slot>
      </template>
      <!-- 重试 -->
      <template v-else>
        <div class="appStatus-retry">
          <el-result icon="error" :sub-title="text.custom || text.list.error">
            <template #extra>
              <el-button type="primary" @click="retry">重试</el-button>
            </template>
          </el-result>
        </div>
      </template>
    </template>

    <!-- 状态可加载 -->
    <template v-else-if="statusCode == 'canload'">
      <template v-if="$slots.canload"><slot name="canload"></slot></template>
      <div v-else class="appStatus-footer">
        {{ text.custom || text.list.canload }}
      </div>
    </template>

    <!-- 状态加载中 -->
    <template v-else-if="statusCode == 'loading'">
      <template v-if="$slots.loading"><slot name="loading"></slot></template>
      <div v-else class="appStatus-footer">
        {{ text.custom || text.list.loading }}
      </div>
    </template>

    <!-- 状态完成 -->
    <template v-else-if="statusCode == 'complete'">
      <template v-if="$slots.complete"><slot name="complete"></slot></template>
      <div v-else class="appStatus-footer">
        {{ text.custom || text.list.complete }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  watch: {
    default: {
      immediate: true,
      handler(value) {
        this.statusCode = value;
      },
    },
  },
  data() {
    return {
      /**
       * 状态码
       * init:初始化，canload:可加载，loading:加载中，complete:所有数据加载完成，default:缺省，error:错误，empty:空页面
       */
      statusCode: 'default',
      text: {
        custom: '', // 当前自定义文字
        list: {
          init: '',
          canload: '上滑查看更多',
          loading: '加载中',
          complete: '我是有底线的',
          error: '网络错误',
          empty: '暂无数据',
        }, // 提示文字列表
      },
    };
  },
  methods: {
    /**
     * 设置状态码或返回状态码
     */
    status(status, options) {
      if (status) {
        // 设置状态
        this.statusCode = status;

        // 设置文本
        this.text.custom = options || '';
      } else {
        // 返回状态
        return this.statusCode;
      }
    },

    /**
     * 重试
     */
    retry() {
      // 初始化
      this.status('init', {
        height: this.height,
        text: this.text,
      });

      // 弹出
      this.$emit('error');
    },
  },
};
</script>

<style scoped lang="scss">
// 外框
.appStatus {
  // width: stretch;
  // max-width: 1200px;
  // margin: auto;
  position: relative;
  // width: 100%;
  // min-height: 100%;

  // 流式布局
  &_fluid {
    max-width: initial;
  }

  /* 初始化 */
  &-init {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #fff;
  }

  /* 重试 */
  &-retry {
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  // 空数据
  &-empty {
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  // 底部
  &-footer {
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    color: #a7a7a7;
  }
}

/* 空 */
.empty-container {
  width: 100%;
  min-height: 500px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.empty-content {
  width: 500px;
  text-align: center;
}
</style>
