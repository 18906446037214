<template>
  <div class="appMenu">
    <!-- 顶部 -->
    <div class="appMenu-header">
      <!-- logo -->
      <div class="appMenu-logo"></div>
      <!-- 搜索 -->
      <!-- <div class="appMenu-search">
        <el-autocomplete
          class="appMenu-search-input"
          v-model="searchKey"
          :fetch-suggestions="querySearch"
          value-key="label"
          placeholder="检索内容"
          @select="searchSelect"
          input-style="appMenu-search-input-content"
        ></el-autocomplete>
      </div> -->
    </div>
    <div class="appMenu-primary">
      <el-scrollbar>
        <app-menu-primary :menu="item" v-for="(item, index) in menuList" :key="index" @second="onSecond" @toNav="nav"></app-menu-primary>
      </el-scrollbar>
    </div>

    <teleport to="body">
      <transition name="fade" mode="out-in">
        <div class="appMenu-second" v-show="second.show" @mouseleave="onSecondSlide(false)">
          <app-menu-second v-for="(item, index) in second.menu" :key="index" :menu="item" :level="0" @toNav="nav"></app-menu-second>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import appMenuPrimary from './app-menu-primary.vue';
import appMenuSecond from './app-menu-second';

export default {
  name: 'appMenu',
  components: {
    appMenuPrimary,
    appMenuSecond,
  },
  computed: {
    ...mapState('app', ['menuList']),
    /**
     * 搜索列表
     */
    searchList() {
      return this.menuList.reduce((result, item) => {
        return this.routeList(item, result);
      }, []);
    },
  },
  data() {
    return {
      searchKey: '',
      second: {
        show: false,
        flag: false,
        menu: [],
      },
    };
  },
  watch: {},
  methods: {
    /**
     * 查找当前路由是否存在于menu中
     */
    routeList(menu, result = []) {
      if (menu.children) {
        menu.children.forEach((item) => {
          this.routeList(item, result);
        });
      } else {
        result.push({
          value: menu.path,
          label: menu.name,
        });
      }

      return result;
    },

    /**
     * 路由搜索
     */
    querySearch(queryString, callback) {
      const results = queryString
        ? this.searchList.filter((item) => {
            if (item.label.includes(queryString)) {
              return item;
            }
          })
        : [];
      callback(results);
    },

    /**
     * 搜索跳转
     */
    searchSelect(e) {
      this.$router.push({
        name: e.value,
      });
    },

    onSecond(menu) {
      this.second.menu = menu;
      this.onSecondSlide(Boolean(menu.length));
    },

    onSecondSlide(status) {
      if (status == true) {
        this.second.show = true;
        this.second.flag = true;
      } else {
        this.second.flag = false;
        setTimeout(() => {
          if (this.second.flag == false) {
            this.second.show = false;
          }
        }, 100);
      }
    },

    /**
     * 导航
     * @param {Object} detail 路由详情
     */
    nav(detail) {
      if (detail.menutype == 'left') {
        // 跳转页面
        this.$router.push({
          name: detail.path,
        });
      } else if (detail.menutype == 'blank') {
        window.open(detail.url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.appMenu {
  background-color: #1a1d29;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 56px;

  // 顶部
  &-header {
    background-color: #212533;
    // padding: 0 20px;
    padding-bottom: 10px;
  }

  // 列表
  &-primary {
    height: 0;
    flex: 1;
  }

  // logo
  &-logo {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 56px;
    height: 60px;
    background-position: center 10px;
    background-size: 34px;
    background-repeat: no-repeat;
    background-image: url('~@/assets/image/logo-mini.png');
  }

  // 搜索
  &-search {
    background-color: #212533;

    &-input {
      width: 100%;

      .el-input__inner {
        background-color: transparent;
        box-shadow: 0 0 0 1px #606266 inset;
      }
    }
  }

  &-second {
    background-color: #212533;
    position: fixed;
    top: 0;
    left: 56px;
    width: 250px;
    height: 100vh;
    z-index: 11;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 100ms;
}
.fade-enter-active {
  z-index: 12;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-56px);
}
</style>
