<template>
  <div class="appHeader">
    <!--    <div class="appHeader-left">-->
    <!--      &lt;!&ndash; 左侧按钮 &ndash;&gt;-->
    <!--      <div class="appHeader-button" @click="setMenuSlide(!menuSlide)">-->
    <!--        <div-->
    <!--            class="appHeader-menuSlide"-->
    <!--            :class="{ 'appHeader-menuSlide_show': menuSlide }"-->
    <!--        >-->
    <!--          <app-icon-->
    <!--              class="appHeader-menuSlide-icon"-->
    <!--              name="menu-hide"-->
    <!--              size="20px"-->
    <!--          />-->
    <!--          <app-icon-->
    <!--              class="appHeader-menuSlide-icon"-->
    <!--              name="menu-show"-->
    <!--              size="20px"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- 缓存标签 -->
    <div class="appHeader-cache">
      <el-scrollbar class="appHeader-cache-scroll">
        <div class="appHeader-cache-nav">
          <el-menu :default-active="bar" mode="horizontal" text-color="#fff" active-text-color="#fff" background-color="#4388EF" @select="handleSelect">
            <el-menu-item v-for="(item, index) in menuList" :key="index" :index="index">
              <app-icon class="app-menu-second-icon" :name="item.icon" v-if="item.icon" />
              <div class="appHeader-cache-nav-title">
                {{ item.name }}
              </div>
            </el-menu-item>
          </el-menu>
        </div>
      </el-scrollbar>
    </div>

    <!-- 右侧信息 -->
    <div class="appHeader-right">
      <div class="appHeader-adminType">
        {{ userInfo?.user?.adminTypeName }}
      </div>
      <div class="appHeader-button" @click="showMessage()">
        <app-icon name="message" size="20px" />
      </div>
      <el-popover ref="popoverRef" placement="bottom-end" trigger="hover" :width="100">
        <template #reference>
          <div class="appHeader-userInfo" ref="userInfo">
            <el-avatar class="appHeader-userInfo-avatar" size="small" :src="userInfo?.user?.avatar">
              <img src="@/assets/image/defaultAvatar.jpg" />
            </el-avatar>
            <div class="appHeader-userInfo-username">
              {{ userInfo?.user?.username }}
            </div>
            <app-icon name="arrow-bottom" />
          </div>
        </template>
        <div class="appHeader-userInfo-menu">
          <div class="appHeader-userInfo-menu-item" @click="logout">退出</div>
          <div class="appHeader-userInfo-menu-item" v-if="userInfo.user && userInfo.user.admin_type == 0" @click="this.$refs.password.open()">修改密码</div>
          <div class="appHeader-userInfo-menu-item" @click="getAuth({ showLoading: true })">更新权限</div>
        </div>
      </el-popover>
    </div>

    <password ref="password" @confirm="logout" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import password from './password.vue';

export default {
  name: 'app-header',
  components: {
    password,
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('app', ['menuSlide', 'cache', 'tag']),
    ...mapState('app', ['menuList']),
  },
  data() {
    return {
      bar: 0,
    };
  },
  mounted() {
    if (this.cache.length > 1) {
      this.init();
    } else {
      this.handleSelect(0);
    }
  },
  methods: {
    ...mapActions('user', ['logout', 'getAuth']),
    ...mapMutations('app', ['setMenuSlide', 'removeCache']),
    ...mapMutations('app', ['pushCache']),

    init() {
      for (let i = 0; i < this.menuList.length; i++) {
        const arr = this.find(this.menuList[i]);
        for (let k = 0; k < arr.length; k++) {
          if (arr[k] == this.cache[1].name) {
            this.bar = i;
          }
        }
      }
      this.handleSelect(this.bar);
    },

    find(menu, result = []) {
      if (menu.children?.length) {
        menu.children.forEach((item) => {
          this.find(item, result);
        });
      } else {
        result.push(menu.path);
      }
      return result;
    },

    showMessage() {
      this.$message.warning('暂无通知');
    },

    handleSelect(e) {
      this.$emit('selected', this.menuList[e]);
    },
  },
};
</script>

<style lang="scss" scoped>
.appHeader {
  background: #4388ef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //padding: 0 10px;

  &-button {
    width: 40px;
    height: 40px;
    // background-color: #f2f2f2;
    // color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
  }
  &-adminType {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  &-menuSlide {
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: flex;

    &-icon {
      color: #fff;
      transition: all 250ms;
      transform: translateX(-100%);
    }

    &_show &-icon {
      transform: translateX(0);
    }
  }

  &-right {
    display: flex;
    gap: 10px;
  }

  &-left {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
  }

  &-cache {
    height: 100%;
    flex: 1;
    width: 0;
    display: flex;
    align-items: center;
    //padding-left: 20px;

    &-scroll {
      width: 100%;
    }

    &-nav {
      width: 100%;
      height: 66px;
      :deep(.el-menu) {
        height: 66px;
        font-size: 18px !important;
        border-bottom: solid 0 #f2f1f6 !important;
        .el-menu--horizontal {
          border-bottom: solid 0 #f2f1f6 !important;
          font-size: 18px !important;
        }
        .el-menu-item {
          font-size: 18px !important;
        }
        .is-active {
          background: rgba(255, 255, 255, 0.14);
          border-top: 2px solid #ffffff;
          border-bottom: 0 solid #ffffff;
        }
      }

      &-title {
        font-size: 16px;
        margin-left: 4px;
      }
    }

    &-list {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 55px;
      flex-wrap: nowrap;
    }

    &-item {
      height: 32px;
      border-radius: 4px;
      padding: 0 10px;
      // padding-left: 10px;
      display: flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
      font-size: 16px;
      color: color.$tableText;
      background: color.$tableBackground;
      white-space: nowrap;

      &_active {
        background: color.$sub;
      }

      &-close {
        margin-left: 10px;
        transition: 250ms;
        border-radius: 5px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -6px;

        &:hover {
          // filter: drop-shadow(0 0 2px rgba($color: #fff, $alpha: 0.5));
          // transform: rotate(360deg);

          background-color: lighten(color.$base, 10%);
          color: #fff;
        }
      }
    }
  }

  &-userInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;

    &-name {
      font-size: 12px;
    }

    &-menu {
      text-align: center;

      &-item {
        cursor: pointer;
        height: 32px;
        line-height: 32px;

        :hover {
          color: color.$base;
        }
      }
    }
  }
}
</style>
