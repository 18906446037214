import router from '@/router';

export default {
  namespaced: true,
  state: () => ({
    menuList: [],
    nodeList: [],
    menuSlide: true,
    cache: [], // 缓存页面的列表
    tag: [], // 由于某些原因需要单独拉一份标签列表
  }),
  getters: {},
  mutations: {
    /**
     * 设置菜单列表
     * @param {Array} list
     */
    setMenuList(state, list) {
      function menuFilter(list) {
        return list.reduce((result, item) => {
          if (item.type == 'menu') {
            if (item.children) {
              item.children = menuFilter(item.children);
            }
            result.push(item);
          }
          return result;
        }, []);
      }
      state.menuList = menuFilter(JSON.parse(JSON.stringify(list)));
    },
    /**
     * 设置节点列表
     * @param {Array} list
     */
    setNodeList(state, list) {
      function nodeFilter(list) {
        return list.reduce((result, item) => {
          if (item.type == 'node') {
            result.push(item);
          } else {
            if (item.children?.length) {
              result = result.concat(nodeFilter(item.children));
            }
          }
          return result;
        }, []);
      }
      state.nodeList = nodeFilter(JSON.parse(JSON.stringify(list)));
    },
    /**
     * 设置菜单显示
     * @param {Boolean} status
     */
    setMenuSlide(state, status) {
      state.menuSlide = status;
    },
    /**
     * 增加页面缓存
     * @param {Boolean} status
     */
    pushCache(state, status) {
      // 如果重置缓存则先清除缓存，在进行添加
      if (status.resetCache) {
        // 获取缓存标签下标
        const cacheIndex = state.cache.findIndex((item) => {
          return item.name == status.name;
        });

        // 如果缓存存在，则删除
        if (cacheIndex >= 0) {
          state.cache.splice(cacheIndex, 1);
        }

        // 如果标签不存在则添加标签
        if (
          !state.tag.some((item) => {
            return item.name == status.name;
          })
        ) {
          state.tag.push(status);
        }

        // 添加缓存
        setTimeout(() => {
          state.cache.push(status);
        }, 0);
      } else {
        if (
          !state.cache.some((item) => {
            return item.name == status.name;
          })
        ) {
          state.cache.push(status);
        }

        if (
          !state.tag.some((item) => {
            return item.name == status.name;
          })
        ) {
          state.tag.push(status);
        }
      }
    },
    /**
     * 删除页面缓存
     * @param {Boolean} status
     */
    removeCache(state, status) {
      if (status) {
        // 获取缓存标签下标
        const cacheIndex = state.cache.findIndex((item) => {
          return item.name == status;
        });

        // 如果缓存存在，则删除
        if (cacheIndex >= 0) {
          state.cache.splice(cacheIndex, 1);

          // 获取标签下标
          const tagIndex = state.tag.findIndex((item) => {
            return item.name == status;
          });

          // 如果标签存在则删除标签
          if (tagIndex >= 0) {
            // 获取当前
            const currentIndex = state.tag.findIndex((item) => router.currentRoute.value.name == item.name);

            // 判断是否跳转
            if (currentIndex == tagIndex) {
              router.push({
                name: state.tag[currentIndex - 1].name,
              });
            }

            // 删除标签
            state.tag.splice(tagIndex, 1);
          }
        }
      } else {
        state.cache = [];
        state.tag = [];
      }
    },
  },
  actions: {},
};
