<template>
  <div class="app-extra">
    <el-row class="app-extra-row" :gutter="20">
      <slot name="default"></slot>
    </el-row>
    <div class="app-extra-bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-extra {
  border: 1px solid color.$border;
  border-radius: 4px;
  width: stretch;
  padding: 30px 30px 10px 30px;
  margin-bottom: 20px;

  // .app-extra-row:not(:empty) + .app-extra-bottom:not(:empty) {
  //   margin-top: 30px;
  // }
  // display: flex;
  // flex-direction: column;
  // justify-items: flex-start;
  // gap: 30px;
}
</style>
